export const Designer = {
  allTasksCompletion: 'showAllTasksCompletion',
  shouldGivePlayerScore: 'shouldGivePlayerScore',
  shouldGiveTeamScore: 'shouldGiveTeamScore'
};

export const PreGame = {
  lobby: 'showPreGameLobby',
  teamRequired: 'isPreGameTeamRequired',
  info: 'showPreGameInfo',
  teams: 'showPreGameTeams',
  createTeams: 'showPreGameCreateTeams',
  joinLeaveTeams: 'showPreGameJoinLeaveTeams',
  inventory: 'showPreGameInventory',
  roles: 'showPreGameRoles',
  scoreboard: 'showPreGameScoreboard',
  chats: 'showPreGameChat',
  facilitatorChat: 'showPreGameFacilitatorChat',
  allPlayerChat: 'showPreGameAllPlayerChat',
  directChat: 'showPreGameDirectChat',
  teamChat: 'showPreGameTeamChat',
  groupChat: 'showPreGameGroupChat',
  trade: 'showPreGameTrade'
};

export const InGame = {
  information: 'showInGameInfo',
  teams: 'showInGameTeams',
  createTeams: 'showInGameCreateTeams',
  joinLeaveTeams: 'showInGameJoinLeaveTeams',
  inventory: 'showInGameInventory',
  roles: 'showInGameRoles',
  scoreboard: 'showInGameScoreboard',
  playerTeamScore: 'showInGamePlayerTeamScore',
  leaderboard: 'showInGameLeaderboard',
  chats: 'showInGameChat',
  facilitatorChat: 'showInGameFacilitatorChat',
  allPlayerChat: 'showInGameAllPlayerChat',
  directChat: 'showInGameDirectChat',
  teamChat: 'showInGameTeamChat',
  groupChat: 'showInGameGroupChat',
  trade: 'showInGameTrade',
  tasksRequireProximity: 'tasksRequireProximity',
  setTaskProximity: 'setTaskProximity',
  premessage: 'enablePremessageOutsideRange',
  showTasknameVisibility: 'showTasknameVisibility',
  otherPlayerOrTeam: 'showOtherPlayerOrTeamOnMap',
  showPlayerScoreOnMap: 'showPlayerScoreOnMap',
  showPlayerDetailByClickOnMap: 'showPlayerDetailByClickOnMap'
};
