import { GameDocumentContext } from '../../contexts/game-document';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Toolbar from './toolbar';

import { EntityEditor } from '../../types/game-document/entity-editor';
import {
  AreaEntity,
  MapEntity,
  ResourceEntity,
  TaskEntity,
  ZoneEntity
} from '../../types/game-document/';
import { Coordinate } from '../../types/place';
import MapCanvas from '../../components/map/map-canvas';
import MapIllustrationCanvas from '../../components/map/map-illustration-canvas';
import { toLonLat } from 'ol/proj';
import { v4 as uuidv4 } from 'uuid';
import MapEditor from '../../components/map/map-editor';
import {
  AddAreaEntityAsync,
  AddResourceAsync,
  AddTaskEntityAsync,
  AddZoneEntityAsync,
  DeleteResourceAsync,
  GetAreasByZoneId,
  GetMapByIdAsync,
  GetResourceValue,
  GetTaskById,
  GetTasksByZoneId,
  GetZoneById,
  ResourceIdExistsAsync,
  UpdateAreaAsync,
  UpdateGameDocState,
  UpdateMapAsync,
  UpdateTaskAsync,
  UpdateZone,
  UpdateZoneAsync
} from '../../utils/game-document';
import { ZoneEditorWindow } from '../../features/game-document/zones';
import { TaskEditorWindow } from '../../features/game-document/tasks';
import { AreaEditorWindow } from '../../features/game-document/areas';
import { uuid } from '../../types/common-helper';
import cloneDeep from 'lodash.clonedeep';
import { MapZoneContext } from '../../components/map/map-zone-context';
import YesNoDialog from '../../components/dialogs/yes-no-dialog';
import { YesNoDialogResult } from '../../types/dialog-result';
import { TaskRoute } from '../../types/game-document/entities/routes';
import { MapImage } from '../../types/game-document';
import { UpdateMapImageAsync } from '../../utils/game-document/assets/map-image';
import { useGameDocumentResources } from '../../hooks/use-game-document-resources';
import { useGameTask } from '../../hooks/use-game-task';
import { ZoneAssetId } from '../../types/game-document/entities/zone';

export type MapsOptions = 'GPS Map' | 'Illustration Map';
export type MapAction = 'Zone' | 'Task' | 'Area' | 'Annotation' | 'Images' | '';
/**
 *
 * @returns WorldMap
 */
const GameMapZone = () => {
  const [state, setState] = useContext(GameDocumentContext);
  const gameDocumentFiles = useGameDocumentResources();
  const map = useContext(MapZoneContext);
  const titleGuid = uuid();
  const imageGuid = uuid();
  const completeImageGuid = uuid();
  const { zoneId } = useParams();
  const [coordinate, setCoordinate] = useState<Coordinate>();
  const [activeAddZoneState, setActiveAddZoneState] = useState<boolean>(false);
  const [isShowChangeMapConfirmation, setIsShowChangeMapconfirmation] =
    useState<boolean>(false);
  const [selectAllAssets, setSelectAllAssets] = useState<string>('');
  const [entityZoneEditorIsVisible, setEntityZoneEditorIsVisible] =
    useState<boolean>(false);
  const toggleZoneEntityEditor = () => {
    setEntityZoneEditorIsVisible(!entityZoneEditorIsVisible);
  };

  const [entityAreaEditorIsVisible, setEntityAreaEditorIsVisible] =
    useState<boolean>(false);
  const toggleAreaEntityEditor = () => {
    setEntityAreaEditorIsVisible(!entityAreaEditorIsVisible);
  };

  const [entityTaskEditorIsVisible, setEntityTaskEditorIsVisible] =
    useState<boolean>(false);
  const toggleTaskEntityEditor = () => {
    setEntityTaskEditorIsVisible(!entityTaskEditorIsVisible);
  };
  const [entityTaskEditorValue, setEntityTaskEditorValue] =
    useState<EntityEditor<TaskEntity>>();

  const [entityZoneEditorValue, setEntityZoneEditorValue] =
    useState<EntityEditor<ZoneEntity>>();

  const [entityAreaEditorValue, setEntityAreaEditorValue] =
    useState<EntityEditor<AreaEntity>>();

  const [resourceEditorValue, setResourceEditorValue] =
    useState<EntityEditor<ResourceEntity>[]>();

  const [isClearInteractions, setIsClearInteractions] =
    useState<boolean>(false);

  const [mapAssetId, setMapAssetId] = useState<string>('');
  const [mapZoneTitleName, setMapZoneTitleName] = useState<string>('');

  const { gameDocument } = state;
  const [assetMap, setAssetMap] = useState<MapEntity>();

  const [tempZone, setTempZone] = useState<ZoneEntity>({
    id: '',
    name: '',
    description: ''
  });
  const [areas, setAreas] = useState<AreaEntity[]>([]);
  const [tempArea, setTempArea] = useState<AreaEntity>({
    id: '',
    name: '',
    description: ''
  });
  const [tempTask, setTempTask] = useState<TaskEntity>({
    id: '',
    name: '',
    description: ''
  });

  const [zones, setZones] = useState<ZoneEntity[]>([]);
  const [tasks, setTasks] = useState<TaskEntity[]>([]);
  const [routes, setRoutes] = useState<TaskRoute[]>([]);

  const [selectedPanel, setSelectedPanel] = useState<MapAction>('');

  const [mapInfo, setMapInfo] = useState<MapEntity>();

  const entity: TaskEntity = {
    id: '',
    name: '',
    description: '',
    titleResId: titleGuid,
    imageResId: imageGuid,
    completeImageResId: completeImageGuid,
    hideWhenComplete:
      state.gameDocument && !state.gameDocument.settings.designer
        ? false
        : !Boolean(
            state.gameDocument?.settings.designer?.taskCompletionVisibility
          )
  };

  const titleResource: ResourceEntity = {
    id: titleGuid,
    name: 'Title',
    description: '',
    type: 'text',
    value: ''
  };

  const { imageRes: imageResource, completeImageRes: completeImageResource } =
    gameDocumentFiles.createNewTaskResources(imageGuid, completeImageGuid);

  //Zones
  const setToBrowserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        setCoordinate({
          lat: pos.coords.latitude,
          lng: pos.coords.longitude
        });
      });
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  };

  const getMapInfo = () => {
    let center = map.getView().getCenter();
    if (
      center === undefined ||
      center[0] === undefined ||
      center[1] === undefined
    ) {
      return undefined;
    }
    const latLon = toLonLat(center);
    const currentCoordinate: Coordinate = {
      lat: latLon[1],
      lng: latLon[0]
    };
    const zoom = map.getView().getZoom() ?? 4;

    const mapEntity: MapEntity = {
      titleResId: assetMap?.id,
      id: assetMap?.id || uuidv4(),
      longitude: assetMap?.longitude,
      latitude: assetMap?.latitude,
      name: assetMap?.name || '',
      description: assetMap?.description || '',
      type: 'openStreetMap',
      zoomLevel: assetMap?.zoomLevel,
      url: `https://www.openstreetmap.org/#map=${zoom}/${currentCoordinate.lat}/${currentCoordinate.lng}"`,
      imageResId: assetMap?.imageResId ?? ''
    };

    setMapInfo(mapEntity);
    return mapEntity;
  };

  const populateWorldMapZones = async () => {
    if (gameDocument) {
      let zones: ZoneEntity[] = [];
      zones.push(
        gameDocument?.assets?.zones?.find((z) => z.id === zoneId) as ZoneEntity
      );

      setZones(zones);
    }
  };

  const handleZoneEntityEditorSubmit = async (
    editorEntity: EntityEditor<ZoneEntity>
  ) => {
    editorEntity.entity.boundary = tempZone.boundary;
    editorEntity.entity.id = uuidv4();
    editorEntity.entity.isVisible = true;
    if (state.gameDocument) {
      let result = await AddZoneEntityAsync(
        state.gameDocument!,
        editorEntity.entity
      );
      if (result) {
        result.rules.worldMap.zones.push({
          zoneAssId: editorEntity.entity.id,
          timerAssId: state.gameDocument.rules.worldMap.timerAssId
        });
        setState((state) => UpdateGameDocState(state, result));
        setEntityZoneEditorIsVisible(false);
      }
    }
  };

  const onAddZone = (zone: ZoneEntity) => {
    setTempZone({
      id: '',
      name: '',
      description: '',
      boundary: {
        id: uuidv4(),
        type: 'Feature',
        geometry: {
          type: zone.boundary?.type ?? 'Polygon',
          coordinates: zone.boundary?.geometry as any
        }
      }
    });

    setEntityZoneEditorValue({
      isNew: true,
      entity: { id: '', name: '', description: '' }
    });
    toggleZoneEntityEditor();
    setActiveAddZoneState(!activeAddZoneState);
  };

  //Areas
  const populateAreas = () => {
    if (gameDocument && zoneId) {
      const areas = GetAreasByZoneId(gameDocument, zoneId);
      setAreas(areas);
    }
  };

  const onAddArea = (area: AreaEntity) => {
    setTempArea({
      id: uuidv4(),
      name: '',
      description: '',
      boundary: {
        id: uuidv4(),
        type: 'Feature',
        geometry: {
          type: area.boundary?.type ?? 'Polygon',
          coordinates: area.boundary?.geometry as any
        }
      }
    });

    setEntityAreaEditorValue({
      isNew: true,
      entity: { id: '', name: '', description: '' }
    });
    toggleAreaEntityEditor();
  };

  const handleAreaEntityEditorSubmit = async (
    editorEntity: EntityEditor<AreaEntity>
  ) => {
    editorEntity.entity.boundary = tempArea.boundary;
    editorEntity.entity.id = uuidv4();
    editorEntity.entity.isVisible = true;

    if (state.gameDocument && zoneId) {
      await AddAreaEntityAsync(state.gameDocument, editorEntity.entity).then(
        (updatedGameDocument) => {
          let zone = GetZoneById(updatedGameDocument, zoneId);

          if (zone) {
            if (zone?.areas === undefined) {
              zone.areas = [];
            }

            zone.areas.push({ areaAssId: editorEntity.entity.id });

            UpdateZoneAsync(updatedGameDocument, zoneId, zone).then(
              (updatedGameDocument) => {
                setState((prev) =>
                  UpdateGameDocState(prev, updatedGameDocument)
                );
              }
            );
            toggleAreaEntityEditor();
          }
        }
      );
    }
  };
  /**
   * Handle when area resize, move or edit
   * @param area
   */
  const handleEditAreaChange = (area: AreaEntity) => {
    UpdateAreaAsync(gameDocument!, area.id, area).then(
      (updatedGameDocument) => {
        setState((state) => UpdateGameDocState(state, updatedGameDocument));
      }
    );
  };

  const populateTask = () => {
    let newBoundaries: TaskEntity[] = [];

    if (zoneId) {
      newBoundaries = GetTasksByZoneId(zoneId, gameDocument);
      setTasks(newBoundaries);
    }
  };

  /**
   * Add task marker/pin from existing task
   * @param taskId
   */
  const handleSelectedExistingTask = (taskId: string) => {
    let task = GetTaskById(state?.gameDocument!, taskId);
    if (task) {
      task!.boundary = tempTask.boundary;
      task.isVisible = true;

      UpdateTaskAsync(state?.gameDocument!, task.id, task).then((result) => {
        if (zoneId) {
          let zone = GetZoneById(result, zoneId);

          if (zone) {
            if (zone?.tasks === undefined) {
              zone.tasks = [];
            }
            zone.tasks.push({ taskAssId: task!.id });
            UpdateZoneAsync(state.gameDocument!, zoneId, zone).then((res) => {
              setState((prev) => UpdateGameDocState(prev, res));
              setEntityTaskEditorIsVisible(false);
            });
          }
        }
        setState((prev) => UpdateGameDocState(prev, result));
        setEntityTaskEditorIsVisible(false);
      });
    }
  };

  const handleTaskEntityEditorSubmit = async (
    editorEntity: EntityEditor<TaskEntity>,
    resourceEntity: EntityEditor<ResourceEntity>[]
  ) => {
    const addTitleResource = AddResourceAsync(
      state.gameDocument!,
      'Title',
      '',
      'text',
      resourceEntity.find(
        (task) => task.entity.id === editorEntity.entity.titleResId!
      )?.entity.value!,
      titleGuid
    );

    const imageResource = resourceEntity.find(
      (task) => task.entity.id === editorEntity.entity.imageResId!
    )?.entity;
    const addImageResource = AddResourceAsync(
      state.gameDocument!,
      imageGuid,
      imageResource?.description ?? '',
      'image',
      imageResource?.value ?? '',
      imageGuid,
      imageResource?.size ?? 0
    );

    const completeImageResource = resourceEntity.find(
      (task) => task.entity.id === editorEntity.entity.completeImageResId!
    )?.entity;
    const addCompleteImageResource = await AddResourceAsync(
      state.gameDocument!,
      completeImageGuid,
      completeImageResource?.description ?? '',
      'image',
      completeImageResource?.value ?? '',
      completeImageGuid,
      completeImageResource?.size ?? 0
    );

    Promise.all([
      addTitleResource,
      addImageResource,
      addCompleteImageResource
    ]).then(() => {
      editorEntity.entity.boundary = tempTask.boundary;
      editorEntity.entity.id = uuidv4();
      editorEntity.entity.isVisible = true;
      editorEntity.entity.imageResId = imageGuid;
      editorEntity.entity.titleResId = titleGuid;
      editorEntity.entity.completeImageResId = completeImageGuid;
      if (state.gameDocument) {
        AddTaskEntityAsync(state.gameDocument!, editorEntity.entity).then(
          (result) => {
            if (zoneId) {
              let zone = GetZoneById(result, zoneId);

              if (zone) {
                if (zone?.tasks === undefined) {
                  zone.tasks = [];
                }
                zone.tasks.push({ taskAssId: editorEntity.entity.id });
                UpdateZoneAsync(state.gameDocument!, zoneId, zone).then(
                  (res) => {
                    setState((prev) => UpdateGameDocState(prev, res));
                    setEntityTaskEditorIsVisible(false);
                  }
                );
              }
            }
          }
        );
      }
    });
  };

  const onAddTask = (coordinates: [number, number]) => {
    setTempTask({
      id: '',
      name: '',
      description: '',
      boundary: {
        id: uuidv4(),
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates
        }
      }
    });

    setEntityTaskEditorValue({
      isNew: true,
      entity: entity
    });

    setResourceEditorValue([
      {
        isNew: true,
        entity: titleResource
      },
      {
        isNew: true,
        entity: imageResource
      },
      {
        isNew: true,
        entity: completeImageResource
      }
    ]);

    toggleTaskEntityEditor();
    setActiveAddZoneState(!activeAddZoneState);
  };

  /**
   * Handle when zone resize, move or edit
   * @param worldZones
   */
  const handleEditZoneChange = async (zone: ZoneEntity) => {
    setState((prev) => {
      const resp = UpdateZone(prev.gameDocument!, zone.id, zone);
      return UpdateGameDocState(prev, resp);
    });
  };

  const updateMapAsset = async (map: MapEntity) => {
    UpdateMapAsync(state?.gameDocument!, map.id, map).then((response) => {
      setState((prev) => UpdateGameDocState(prev, response));
      setAssetMap(map);
    });
  };

  const onChangeMapOptions = async (MapsOptions: MapsOptions) => {
    if (mapAssetId !== undefined && mapAssetId !== '') {
      let mapEntity = await GetMapByIdAsync(state?.gameDocument!, mapAssetId);
      if (mapEntity) {
        if (MapsOptions === 'GPS Map') {
          const isMapExist =
            mapEntity.imageResId &&
            (await ResourceIdExistsAsync(
              state.gameDocument?.resources ?? [],
              mapEntity.imageResId
            ));
          if (isMapExist) {
            setIsShowChangeMapconfirmation(true);
          } else {
            mapEntity.type = 'openStreetMap';
            await updateMapAsset(mapEntity);
          }
        } else if (MapsOptions === 'Illustration Map') {
          mapEntity.type = 'illustration';
          mapEntity.longitude = undefined;
          mapEntity.latitude = undefined;
          mapEntity.zoomLevel = undefined;
          await updateMapAsset(mapEntity);
        }
      }
    }
  };

  const handleConfirmChangeMap = async (result: YesNoDialogResult) => {
    if (result === 'yes') {
      let mapEntity = await GetMapByIdAsync(state?.gameDocument!, mapAssetId!);
      if (mapEntity) {
        const updatedGameDocument = await DeleteResourceAsync(
          state.gameDocument!,
          mapEntity.imageResId!
        );
        setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
        mapEntity.imageResId = '';
        mapEntity.type = 'openStreetMap';
        await updateMapAsset(mapEntity);
      }
    }
    setIsShowChangeMapconfirmation(false);
  };

  const populateMapAsset = async (mapAssetId: string) => {
    let mapAsset = await GetMapByIdAsync(state?.gameDocument!, mapAssetId);
    if (mapAsset) {
      setAssetMap(mapAsset);
    }
  };

  //Routes
  const populateAssetRoutes = () => {
    if (state.gameDocument) {
      const allRoute = state?.gameDocument?.assets?.routes ?? [];
      if (mapAssetId) {
        setRoutes(allRoute.filter((item) => item.mapId === mapAssetId));
      } else {
        setRoutes(allRoute);
      }
    }
  };

  const handleEditTask = (task: TaskEntity) => {
    UpdateTaskAsync(state?.gameDocument!, task.id, task).then((response) => {
      setState((prev) => UpdateGameDocState(prev, response));
    });
  };

  const onSelectedPanel = (MapAction: MapAction) => {
    setSelectedPanel(MapAction);
  };

  const onEditMapImage = (mapImage: MapImage) => {
    UpdateMapImageAsync(state?.gameDocument!, mapImage.id, mapImage).then(
      (response) => {
        setState((prev) => UpdateGameDocState(prev, response));
      }
    );
  };

  useEffect(() => {
    populateTask();
    populateAreas();
    populateWorldMapZones();
    populateMapAsset(mapAssetId);
    populateAssetRoutes();
    document.title = `Maps - ${state.gameDocument?.name}`;
  }, [state]);

  useEffect(() => {
    setIsClearInteractions(true);
  }, []);

  useEffect(() => {
    if (map) {
      getMapInfo();
    }
  }, [map]);

  useEffect(() => {
    const mapAssId = gameDocument?.rules.worldMap.zones.find(
      (z) => z.zoneAssId === zoneId
    )?.mapAssId;

    if (mapAssId !== undefined) {
      setMapAssetId(mapAssId);

      populateMapAsset(mapAssId);
    }
  }, [zoneId]);

  useEffect(() => {
    const zone = GetZoneById(gameDocument, zoneId!);
    if (zone) {
      const zoneRes = state.gameDocument?.resources.find(
        (item) => item.id === zone.titleResId!
      )! as ResourceEntity;
      if (zoneRes) {
        setMapZoneTitleName(zoneRes.value!);
      }
    }
    return () => setMapZoneTitleName('');
  }, [state.gameDocument?.assets.zones]);

  const getMapImages = () => {
    if (state.gameDocument) {
      if (zoneId) {
        return state?.gameDocument?.assets?.mapImages?.filter(
          (item) =>
            item.mapId ===
              state.gameDocument?.rules.worldMap.zones.find(
                (zone: ZoneAssetId) => zone.zoneAssId === zoneId
              )?.mapAssId || ''
        );
      } else {
        return (
          state?.gameDocument?.assets?.mapImages?.filter(
            (item) =>
              item.mapId === state?.gameDocument?.rules?.worldMap?.mapAssId!
          ) ?? []
        );
      }

      return [];
    }
    return [];
  };

  const {
    populateTasks,
    removeTaskOverlay,
    addTaskOverlay,
    updateTaskOverlayPosition
  } = useGameTask({
    selectAllAssets,
    tasks: tasks || [],
    zones: zones || [],
    mapImages: getMapImages() || [],
    mapType: assetMap?.type || ''
  });

  useEffect(() => {
    populateTasks();
  }, [tasks]);

  return (
    <>
      <Toolbar title={mapZoneTitleName}></Toolbar>
      <div className={'map-designer'}>
        <div className={'layout__left'}>
          {(assetMap?.type === 'openStreetMap' ||
            assetMap?.type === 'satelliteMap') && (
            <MapCanvas
              coordinate={cloneDeep(coordinate)}
              zones={zones}
              tasks={tasks}
              areas={areas}
              routes={routes}
              resourceImages={gameDocument?.resources}
              isClearInteractions={isClearInteractions}
              onEditZones={handleEditZoneChange}
              onAddZone={onAddZone}
              onAddTask={onAddTask}
              onAddArea={onAddArea}
              onEditAreas={handleEditAreaChange}
              onEditTasks={handleEditTask}
              onEditMapImages={onEditMapImage}
              selectedPanel={selectedPanel}
              isEditZone={true}
              mapImages={state.gameDocument?.assets.mapImages?.filter(
                (item) => mapAssetId === item.mapId
              )}
              selectedObjectType={(selectAllAssets as any) ?? ''}
              onRemoveSelectAllAssets={() => setSelectAllAssets('')}
              addTaskOverlay={addTaskOverlay as any}
              removeTaskOverlay={removeTaskOverlay}
              updateTaskOverlayPosition={updateTaskOverlayPosition as any}
            />
          )}
          {assetMap?.type === 'illustration' && (
            <MapIllustrationCanvas
              zones={zones}
              tasks={tasks}
              onEditZones={handleEditZoneChange}
              onAddZone={onAddZone}
              onAddTask={onAddTask}
              resourceImages={gameDocument?.resources}
              url={GetResourceValue(
                state?.gameDocument!,
                assetMap!.imageResId!
              )}
              isClearInteractions={isClearInteractions}
              selectedPanel={selectedPanel}
              isEditZone={true}
              onEditTasks={handleEditTask}
              onEditMapImages={onEditMapImage}
              assetMap={assetMap}
              mapImages={state.gameDocument?.assets.mapImages?.filter(
                (item) => mapAssetId === item.mapId
              )}
              selectedObjectType={(selectAllAssets as any) ?? ''}
              onRemoveSelectAllAssets={() => setSelectAllAssets('')}
              addTaskOverlay={addTaskOverlay as any}
              removeTaskOverlay={removeTaskOverlay}
              updateTaskOverlayPosition={updateTaskOverlayPosition as any}
            />
          )}
        </div>

        <div className={'layout__right'}>
          <MapEditor
            onChangeMapOptions={onChangeMapOptions}
            onSelectedPanel={onSelectedPanel}
            showMapAccordion={false}
            showZonesAccordion={true}
            showAreasAccordion={true}
            showTasksAccordion={true}
            zoneId={zoneId}
            mapInfo={mapInfo}
            onCenterMap={setToBrowserLocation}
            mapOptions={
              assetMap?.type === 'openStreetMap' ||
              assetMap?.type === 'satelliteMap'
                ? 'GPS Map'
                : 'Illustration Map'
            }
            currentImageResource={
              assetMap
                ? GetResourceValue(state?.gameDocument!, assetMap!.imageResId!)
                : ''
            }
            onSelectAllAssets={(value) => setSelectAllAssets(value)}
            selectedAllObjectType={selectAllAssets}
            removeTaskOverlay={removeTaskOverlay}
          />

          {entityZoneEditorIsVisible && (
            <ZoneEditorWindow
              toggleDialog={toggleZoneEntityEditor}
              onSubmit={handleZoneEntityEditorSubmit}
              onClose={toggleZoneEntityEditor}
              editorEntity={entityZoneEditorValue!}
              editorMode={'basic'}
              editorResource={[]}
            />
          )}

          {entityAreaEditorIsVisible && (
            <AreaEditorWindow
              toggleDialog={toggleAreaEntityEditor}
              onSubmit={handleAreaEntityEditorSubmit}
              onClose={toggleAreaEntityEditor}
              editorEntity={entityAreaEditorValue!}
              editorMode={'basic'}
            />
          )}

          {entityTaskEditorIsVisible && (
            <TaskEditorWindow
              toggleDialog={toggleTaskEntityEditor}
              onSubmit={handleTaskEntityEditorSubmit}
              onSelectedExistingTask={handleSelectedExistingTask}
              showExistingTask={true}
              onClose={toggleTaskEntityEditor}
              editorEntity={entityTaskEditorValue!}
              editorMode={'basic'}
              editorResource={resourceEditorValue!}
            />
          )}

          {isShowChangeMapConfirmation && (
            <YesNoDialog
              onClose={() => setIsShowChangeMapconfirmation(false)}
              onConfirm={handleConfirmChangeMap}
              title={'Confirm change map type'}>
              Are you sure you want to change? All changes will be lost.
            </YesNoDialog>
          )}
        </div>
      </div>
    </>
  );
};

export default GameMapZone;
