import { useCallback, useEffect, useState } from 'react';
import { PrivatePage } from '../components/private-page';
import { Col, Collapse, Container, Row } from 'react-bootstrap';
import PopupMenu, { popupMenu as MenuList } from '../components/popupMenu';
import { TextArea } from '@progress/kendo-react-inputs';
import { FormInput } from '../components/form-input';
import { ActivityEvent } from '../components/activity-event';
import {
  Event,
  EventFeedback,
  EventPlaylist,
  EventPlaylistFacilitator,
  EventPlaylistLocation
} from '../types/event';
import { Error, Label } from '@progress/kendo-react-labels';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { User } from '../types/user';
import { GetUsersAsync } from '../services/users';
import {
  DeleteEventFeedbackAsync,
  DeleteEventPlaylistAsync,
  DeleteEventPlaylistFacilitatorAsync,
  DeleteEventPlaylistLocationAsync,
  GetEventById,
  GetEventFeedbacks,
  GetEventPlaylistFacilitators,
  GetEventPlaylistLocations,
  GetEventPlaylists,
  PatchEventPlaylistAsync,
  PatchEventPlaylistFacilitatorAsync,
  PatchEventPlaylistLocationAsync,
  PathcEventFeedbackAsync,
  PostEventAsync,
  PostEventFeedbackAsync,
  PostEventPlaylistAsync,
  PostEventPlaylistFacilitatorAsync,
  PostEventPlaylistLocationAsync,
  PutEventAsync
} from '../services/events';
import { Library } from '../types/library';
import { EnvironmentList } from '../types/environment';
import { toastStore } from '../stores/toast-store';
import cloneDeep from 'lodash.clonedeep';
import { ValidationRules, validator } from 'nm-validator';
import { emailAddress, required } from 'nm-validator/dist/validationRules';
import { uuid } from '../types/common-helper';
import { GetCountriesAsync, GetLanguagesAsync } from '../services/country';
import { CountryResponse } from '../types/responses/country-response';
import CoverImage from '../components/cover-image';
import { Button } from '@progress/kendo-react-buttons';
import { GetTimeZonesAsync } from '../services/timezones';
import { TimeZoneResponse } from '../types/responses/timezone-response';
import { GetEnvironmentAsync } from '../services/environments';
import GameSettingsDialog from '../components/dialogs/game-setting-dialog';
import { GameDocument } from '../types/game-document';
import { appStore } from '../stores/app-store';
import { UpdateGameSettingsAsync } from '../utils/game-document';
import UserComboBox from '../components/combobox/user-combobox';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { State, toDataSourceRequestString } from '@progress/kendo-data-query';
import { GetPublishedGamesAsync } from '../services/games';
import {
  ResourceWindow,
  UploadedImage
} from '../features/game-document/image-resource/resource-window';
import { PostMediaAsync } from '../services/files';
import { Feedback } from '../types/feedback';
import { FeedbackEvent } from '../components/feedback-event';
import { getPublishedFeedback } from '../services/feedback';
import { Assessment } from '../types/assessment';
import { getPublishedAssessments } from '../services/assessment';
import { GetUsersPhoneNumbersAsync } from '../services/user-phone-number';
import HelpSupport from '../components/help-support';
import {
  ExpansionPanel,
  ExpansionPanelActionEvent,
  ExpansionPanelContent
} from '@progress/kendo-react-layout';
import { Reveal } from '@progress/kendo-react-animation';

export type dictionary = {
  id: number;
  name: string;
};

export interface EventRequestError {
  name?: string;
  clientName?: string;
  clientEmailAddress?: string;
  directorEmailAddress?: string;
  leadFacilitatorEmailAddress?: string;
  salesEmailAddress?: string;
  imageLogoUrl?: string;
}

type EventPlaylistMapper = {
  eventPlaylist?: EventPlaylist;
  facilitators?: EventPlaylistFacilitator[];
  locations?: EventPlaylistLocation[];
};

export default function MyEventDetails() {
  const { id } = useParams();
  const [users, setUsers] = useState<User[]>([]);
  const [logo, setLogo] = useState({
    url: '',
    fileName: ''
  });
  const navigate = useNavigate();
  const [event, setEvent] = useState<Event>({
    id: 0,
    imageLogoUrl: undefined
  });
  const [eventPlaylists, SetEventPlaylists] = useState<EventPlaylistMapper[]>(
    []
  );
  const [eventFeedbacks, setEventFeedbacks] = useState<EventFeedback[]>([]);
  const [feedbacksList, setFeedbacksList] = useState<Feedback[]>([]);
  const [assessmentList, setAssessmentList] = useState<Assessment[]>([]);
  const [errors, setErrors] = useState<EventRequestError>();
  const [libraries, setLibraries] = useState<Library[]>([]);
  const [environments, setEnvironments] = useState<EnvironmentList[]>([]);
  const [languages, setLanguages] = useState<string[]>([]);
  const [countries, setCountries] = useState<CountryResponse[]>([]);
  const [timezones, setTimezones] = useState<TimeZoneResponse[]>([]);
  const [menuList, setMenuList] = useState<MenuList[]>([]);
  const [openFileDialog, setOpenFileDialog] = useState<boolean>(false);
  const [selectedGameId, setSelectedGameId] = useState<number>(0);
  const [isExpand, setIsExpand] = useState<boolean>(false);

  const populateEvent = () => {
    if (id && id !== 'new') {
      getEventById(parseInt(id));
    }
  };
  const validate = () => {
    const rules: ValidationRules<Event> = {
      name: [required()],
      clientName: [required()]
    };

    let errorEmail = 'Please enter a valid email address';

    if (event.clientEmailAddress && event.clientEmailAddress !== '') {
      rules.clientEmailAddress = [emailAddress(errorEmail)];
    }

    if (event.directorEmailAddress && event.directorEmailAddress !== '') {
      rules.directorEmailAddress = [emailAddress(errorEmail)];
    }

    if (
      event.leadFacilitatorEmailAddress &&
      event.leadFacilitatorEmailAddress !== ''
    ) {
      rules.leadFacilitatorEmailAddress = [emailAddress(errorEmail)];
    }

    if (!event.clientName) {
      let error = 'Client is required.';
      toastStore.show('Error', <div>{error}</div>, 'error');
    }

    if (!event.name) {
      let error = 'Event title is required.';
      toastStore.show('Error', <div>{error}</div>, 'error');
    }

    var validationResult = validator.validateObject<Event>(event, rules);
    setErrors(validationResult.errors);
    return validationResult;
  };

  const [populatePlaylist, setPopulatePlaylist] =
    useState<EventPlaylistMapper>();

  const initialDataState: State = {
    sort: [{ field: 'name', dir: 'asc' }]
  };
  const [dataState] = useState<State>(initialDataState);

  useEffect(() => {
    if (populatePlaylist) {
      if (
        eventPlaylists?.find(
          (x) => x.eventPlaylist?.id === populatePlaylist.eventPlaylist?.id
        ) === undefined
      ) {
        let newPlaylists: EventPlaylistMapper[] = [...eventPlaylists];
        newPlaylists.push(populatePlaylist);
        newPlaylists.sort(function (a, b) {
          return a.eventPlaylist!.id! - b.eventPlaylist!.id!;
        });
        SetEventPlaylists(newPlaylists);
      }
    }
    //eslint-disable-next-line
  }, [populatePlaylist]);

  const getEventById = async (id: number) => {
    //Get event by id
    GetEventById(id).then(async (responseEvent) => {
      setEvent(responseEvent);

      //Get event playlists
      Promise.all([GetEventPlaylists(id), GetEventFeedbacks(id)]).then(
        (responseData) => {
          responseData[0]?.data?.forEach(async (playlist) => {
            let newMapper: EventPlaylistMapper = {
              eventPlaylist: playlist
            };

            const [facilitators, locations] = await Promise.all([
              GetEventPlaylistFacilitators(id, playlist.id!),
              GetEventPlaylistLocations(id, playlist.id!)
            ]);

            newMapper.facilitators = facilitators?.data ?? [];
            newMapper.locations = locations.data ?? [];
            setPopulatePlaylist(newMapper);
          });

          setEventFeedbacks(responseData![1]?.data);
        }
      );

      if (responseEvent.logoMediaId) {
        setLogo({ url: responseEvent.imageLogoUrl!, fileName: '' });
      }
    });
  };

  //onChangeFacilitatorHandler only happen when facilitator added/deleted
  const onChangeFacilitatorHandler = (
    index: number,
    facilitator: EventPlaylistFacilitator
  ) => {
    let newEventMapper: EventPlaylistMapper[] = [...eventPlaylists];

    if (newEventMapper[index]?.facilitators === undefined) {
      newEventMapper[index].facilitators = [];
    }

    const facilitatorIndex = newEventMapper[index]?.facilitators?.findIndex(
      (x) => x.facilitatorUserProfileId === facilitator.facilitatorUserProfileId
    );

    if (facilitatorIndex! > -1) {
      if (facilitator.isDeleted) {
        if (facilitator.id === 0) {
          newEventMapper[index].facilitators?.splice(facilitatorIndex!, 1);
        }
        if (facilitator.id! > 0) {
          newEventMapper[index].facilitators![facilitatorIndex!].isDeleted =
            facilitator.isDeleted;
        }
      }
    }

    if (facilitatorIndex === -1 || facilitatorIndex === undefined) {
      newEventMapper[index].facilitators?.push(facilitator);
    }

    SetEventPlaylists(newEventMapper);
  };

  const onChangeLocationHandler = (
    index: number,
    locations: EventPlaylistLocation[]
  ) => {
    const newEventMapper: EventPlaylistMapper[] = [...eventPlaylists];
    const selectedEventActivity = newEventMapper[index];

    if (selectedEventActivity.locations === undefined) {
      selectedEventActivity.locations = [];
    }

    selectedEventActivity.locations = locations;
    SetEventPlaylists(newEventMapper);
  };

  const getLibraries = async () => {
    // let response = await getMyLibraries();
    const compositeDescriptor = `${toDataSourceRequestString(dataState)}`;
    GetPublishedGamesAsync(compositeDescriptor)
      .then((response) => {
        const libs = response.data as Library[];
        const sortedGame = libs.sort((a, b) => {
          const order: { [key: string]: number } = {
            AR: 0,
            VR: 1,
            Game: 2
          };
          return order[a.type] - order[b.type];
        });
        setLibraries(sortedGame);
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  const getEnvironments = async () => {
    const response = await GetEnvironmentAsync();

    if (response.data) {
      setEnvironments(response.data);
    }
  };

  const getLanguages = async () => {
    const response = await GetLanguagesAsync();
    const languages: string[] = [];
    response.data.forEach((f) => {
      if (!languages.some((c) => c === f.language)) {
        languages.push(f.language!);
      }
    });
    setLanguages(languages);
  };

  const getCountries = async () => {
    GetCountriesAsync().then((res) => setCountries(res.data));
  };

  const getTimeZones = async () => {
    const response = await GetTimeZonesAsync();
    if (response) {
      setTimezones(response.data);
    }
  };

  const handleCopyGame = (index: number) => {
    if (index !== undefined) {
      let evt: EventPlaylistMapper[] = [...eventPlaylists];

      if (evt) {
        //Copy the game
        let copyGame = cloneDeep(
          evt.filter((x) => !x.eventPlaylist!.isDeleted)[index]
        );
        if (copyGame) {
          //set game.id to 0
          copyGame.eventPlaylist!.id = 0;
          //set facilitators.id = 0
          copyGame.facilitators?.forEach((fcl) => {
            fcl.id = 0;
          });
          //set locations.id = 0
          copyGame?.locations?.forEach((loc) => {
            loc.id = 0;
          });
          evt.push(copyGame);
          SetEventPlaylists(evt);
        }
      }
    }
  };

  const onGameChanged = (game: EventPlaylist, index: number) => {
    if (game) {
      let evtPlaylist: EventPlaylistMapper[] = [
        ...eventPlaylists.filter((x) => !x.eventPlaylist!.isDeleted!)
      ];

      if (evtPlaylist) {
        evtPlaylist[index].eventPlaylist = game;
        SetEventPlaylists(evtPlaylist);
      }
    }
  };

  const handleDeleteGame = (index: number) => {
    if (index !== undefined) {
      let data: EventPlaylistMapper[] = [
        ...eventPlaylists.filter((x) => !x.eventPlaylist!.isDeleted!)
      ];

      if (data) {
        if (data[index]?.eventPlaylist!.id! > 0) {
          data[index].eventPlaylist!.isDeleted = true;
        }
        if (data[index].eventPlaylist!.id! === 0) {
          data.splice(index, 1);
        }
      }
      SetEventPlaylists(data);
    }
  };

  const onChange = (field: keyof Event, value: string) => {
    let newEvent = { ...event };
    if (newEvent && field) {
      newEvent[field] = value as never;
    }

    setEvent(newEvent);
  };

  const getPhoneNumber = async (userId: number) => {
    const response = await GetUsersPhoneNumbersAsync(userId);
    const phoneNumber = response?.data.find((o) => o.type === 'Work');

    if (!phoneNumber) return '';
    return (
      (phoneNumber.countryCode ? `+${phoneNumber.countryCode}` : '') +
      phoneNumber.number
    );
  };

  const onChangeUserEvent = async (
    changeEvent: ComboBoxChangeEvent,
    userCategory: string
  ) => {
    let userProfileId: number | undefined = undefined;
    let userEmailAddress: string | undefined = undefined;
    let userFullName: string | undefined = undefined;
    let phoneNumber: string = '';
    if (changeEvent.value === null) {
      userProfileId = undefined;
      userEmailAddress = '';
      userFullName = '';
    } else {
      phoneNumber = await getPhoneNumber(changeEvent.value.userId);
      userProfileId = changeEvent.value?.userId;
      userEmailAddress = changeEvent.value?.username;
      userFullName = changeEvent.value?.fullName;
    }
    if (userCategory === 'salesPerson') {
      setEvent({
        ...event,
        salesName: userFullName!,
        salesUserProfileId: userProfileId!,
        salesEmailAddress: userEmailAddress!,
        salesPhoneNumber: phoneNumber
      });
    }

    if (userCategory === 'director') {
      setEvent({
        ...event,
        directorName: userFullName!,
        directorUserProfileId: userProfileId!,
        directorEmailAddress: userEmailAddress!,
        directorPhoneNumber: phoneNumber
      });
    }

    if (userCategory === 'facilitator') {
      setEvent({
        ...event,
        leadFacilitatorName: userFullName!,
        leadFacilitatorUserProfileId: userProfileId!,
        leadFacilitatorEmailAddress: userEmailAddress!,
        leadFacilitatorPhoneNumber: phoneNumber
      });
    }
  };

  const isValidationActivity = (eventPlaylists: EventPlaylistMapper[]) => {
    let counterFalse = 0;
    eventPlaylists.forEach((event) => {
      if (!event.eventPlaylist?.gameId && !event.eventPlaylist?.assessmentId) {
        counterFalse++;
      }
    });
    return counterFalse > 0 ? false : true;
  };

  const onSubmitHandler = async () => {
    const validationResult = validate();
    if (!validationResult.isValid) {
      return;
    }

    // activity validation, at least one activity is required and have one game or assessment is required.
    if (eventPlaylists.length === 0) {
      toastStore.show('Error', <div>Activity is required.</div>, 'error');
      return;
    } else {
      // environment are required and one game or assessment is required.
      if (!isValidationActivity(eventPlaylists)) {
        toastStore.show(
          'Error',
          <div>Game or Assessment is required.</div>,
          'error'
        );
        return;
      } else {
        let isPlaylistValid = true;
        eventPlaylists.forEach((f) => {
          if (f.eventPlaylist?.environmentId === undefined) {
            isPlaylistValid = false;
            return;
          }
          if (
            f.locations?.length === 0 ||
            f.locations?.some((location) => !location.country || !location.city)
          ) {
            isPlaylistValid = false;
            return;
          }
        });
        if (!isPlaylistValid) return;
      }
    }

    //feedbacks form validation
    if (eventFeedbacks.length > 0) {
      const getFeedbacksInValid = eventFeedbacks.find(
        (item) => !item.feedbackId || item.feedbackId === 0
      );
      if (getFeedbacksInValid) {
        return;
      }
    }

    if (id !== 'new') {
      await editEventHandler(parseInt(id!));
      await populateEvent();
      await imageHandler(parseInt(id!));
    } else {
      addEventHandler();
    }
  };

  const imageHandler = async (id: number) => {
    GetEventById(id).then(async (responseEvent) => {
      setEvent(responseEvent);
      if (responseEvent.logoMediaId) {
        setLogo({ url: responseEvent.imageLogoUrl!, fileName: '' });
      }
    });
  };

  const addEventHandler = async () => {
    appStore.showLoading();
    event.type = 'Game';
    event.status = 'New';

    if (!isEmpty(logo.url)) {
      const blobResponse = await fetch(logo.url);
      const blobContent = await blobResponse.blob();
      const media = await PostMediaAsync(
        new File([blobContent], logo.fileName)
      );
      event.logoMediaId = media.id;
      event.imageLogoUrl = media.mediaUrl;
    }

    //Insert a new event
    await PostEventAsync(event)
      .then(async (response) => {
        let counterLoopingPlaylist = 0;
        //Insert a new playlist
        for (const playlist of eventPlaylists) {
          let newPlaylist = playlist.eventPlaylist!;
          newPlaylist.eventId = response.id;
          newPlaylist.status = 'New';
          newPlaylist.imageLogoUrl = response.imageLogoUrl;

          const responsePlaylist = await PostEventPlaylist(
            response.id!,
            playlist.eventPlaylist!,
            playlist.facilitators ?? [],
            playlist.locations ?? []
          );

          if (responsePlaylist && responsePlaylist.code) {
            //add games into feedback state
            addFeedbackGames(responsePlaylist);
          }

          if (counterLoopingPlaylist === eventPlaylists.length - 1) {
            //Insert a new feedback when last looping
            eventFeedbacks
              .filter((item) => item.isDeleted === false)
              .forEach(async (feedbackItem) => {
                await PostEventFeedbackAsync(response.id!, feedbackItem);
              });
          }

          counterLoopingPlaylist++;
        }
        toastrEventSave(false);
        appStore.hideLoading();
        navigate('/myevents');
      })
      .catch((ex) => {
        console.error(ex);
      })
      .finally(() => {
        appStore.hideLoading();
      });
  };

  const onConfirmGameSettingsChanges = useCallback(
    async (gameDocument: GameDocument) => {
      try {
        appStore.showLoading();
        await UpdateGameSettingsAsync(selectedGameId, gameDocument);
      } catch (err) {
        console.error(err);
      } finally {
        appStore.hideLoading();
        setSelectedGameId(0);
      }
    },
    [selectedGameId]
  );

  const banckHandler = () => {
    navigate('/myevents');
  };

  const PostEventPlaylist = async (
    eventId: number,
    eventPlaylist: EventPlaylist,
    facilitators?: EventPlaylistFacilitator[],
    locations?: EventPlaylistLocation[]
  ) => {
    return await PostEventPlaylistAsync(eventId, eventPlaylist!).then(
      (playlistResponse) => {
        facilitators?.forEach((facilitator) => {
          //Insert a new facilitator
          PostEventPlaylistFacilitator(
            eventId,
            playlistResponse.id!,
            facilitator
          );
        });
        //Insert a new locaiton
        locations?.forEach((location) => {
          PostEventPlaylistLocation(eventId, playlistResponse.id!, location);
        });
        return playlistResponse;
      }
    );
  };

  const PatchEventPlaylist = async (
    eventId: number,
    eventPlaylist: EventPlaylist,
    facilitators?: EventPlaylistFacilitator[],
    locations?: EventPlaylistLocation[]
  ) => {
    return await PatchEventPlaylistAsync(
      eventId,
      eventPlaylist.id!,
      eventPlaylist!
    ).then((playlistResponse) => {
      facilitators?.forEach((facilitator) => {
        if (facilitator.isDeleted) {
          DeleteEventPlaylistFacilitatorAsync(
            eventId,
            eventPlaylist.id!,
            facilitator.id!,
            facilitator
          );
        }
        if (!facilitator.isDeleted) {
          if (facilitator.id === 0) {
            //Insert a new facilitator
            PostEventPlaylistFacilitator(
              eventId,
              playlistResponse.id!,
              facilitator
            );
          }
          if (facilitator.id! > 0) {
            //Insert a new facilitator
            PatchEventPlaylistFacilitatorAsync(
              eventId,
              playlistResponse.id!,
              facilitator.id!,
              facilitator
            );
          }
        }
      });

      locations?.forEach((location) => {
        if (location.isDeleted) {
          DeleteEventPlaylistLocationAsync(
            eventId,
            eventPlaylist.id!,
            location.id!,
            location
          );
        }
        if (!location.isDeleted) {
          if (location.id === 0) {
            //Insert a new location
            PostEventPlaylistLocation(eventId, playlistResponse.id!, location);
          }
          if (location.id > 0) {
            //Insert a new location
            PatchEventPlaylistLocationAsync(
              eventId,
              playlistResponse.id!,
              location.id!,
              location
            );
          }
        }
      });
      return playlistResponse;
    });
  };

  const PostEventPlaylistFacilitator = (
    eventId: number,
    playlistId: number,
    facilitator: EventPlaylistFacilitator
  ) => {
    PostEventPlaylistFacilitatorAsync(eventId!, playlistId!, facilitator);
  };

  const PostEventPlaylistLocation = (
    eventId: number,
    playlistId: number,
    location: EventPlaylistLocation
  ) => {
    PostEventPlaylistLocationAsync(eventId!, playlistId!, location);
  };

  const addFeedbackGames = (eventPlaylistsResponse: EventPlaylist) => {
    const feedbackGames = {
      code: eventPlaylistsResponse.code!,
      name: eventPlaylistsResponse.name!
    };

    let newEventFeedback = cloneDeep(
      eventFeedbacks.map((item) => {
        let newItem = item;
        if (newItem.games === undefined) {
          newItem.games = [];
        }
        const isExist = newItem.games.find(
          (i) => i.code === feedbackGames.code
        );
        if (!isExist) {
          newItem.games.push(feedbackGames);
        }
        return newItem;
      })
    );
    setEventFeedbacks(newEventFeedback);
  };

  const editEventHandler = async (eventId: number) => {
    if (eventId) {
      appStore.showLoading();

      const newEvent = cloneDeep(event);
      if (!isEmpty(logo.url) && event.imageLogoUrl !== logo.url) {
        const blobResponse = await fetch(logo.url);
        const blobContent = await blobResponse.blob();
        const media = await PostMediaAsync(
          new File([blobContent], logo.fileName)
        );
        newEvent.logoMediaId = media.id;
        newEvent.imageLogoUrl = media.mediaUrl;
      }

      await PutEventAsync(eventId, newEvent)
        .then((response) => {
          toastrEventSave(false);
          let counterLoopingPlaylist = 0;
          //Insert a new playlist
          eventPlaylists.forEach(async (playlist) => {
            let newPlaylist = playlist.eventPlaylist!;
            newPlaylist.eventId = response.id;
            newPlaylist.status = 'New';
            newPlaylist.imageLogoUrl = response.imageLogoUrl;

            if (playlist.eventPlaylist?.isDeleted) {
              DeleteEventPlaylistAsync(eventId, playlist.eventPlaylist.id!);
            }

            if (!playlist.eventPlaylist?.isDeleted) {
              if (playlist.eventPlaylist?.id === 0) {
                const responsePlaylist = await PostEventPlaylist(
                  eventId,
                  playlist.eventPlaylist,
                  playlist.facilitators ?? [],
                  playlist.locations ?? []
                );
                if (responsePlaylist && responsePlaylist.code) {
                  //add games into feedback state
                  addFeedbackGames(responsePlaylist);
                }
                if (counterLoopingPlaylist === eventPlaylists.length - 1) {
                  //Insert a new feedback when last looping
                  eventFeedbacks
                    .filter((item) => item.isDeleted === false && item.id === 0)
                    .forEach(async (feedbackItem) => {
                      await PostEventFeedbackAsync(response.id!, feedbackItem);
                    });
                }
              }

              if (playlist.eventPlaylist!.id! > 0) {
                const responsePlaylist = await PatchEventPlaylist(
                  eventId,
                  playlist.eventPlaylist!,
                  playlist.facilitators ?? [],
                  playlist.locations ?? []
                );
                if (responsePlaylist && responsePlaylist.code) {
                  //add games into feedback state
                  addFeedbackGames(responsePlaylist);
                }
                if (counterLoopingPlaylist === eventPlaylists.length - 1) {
                  //Update feedback when last looping
                  eventFeedbacks
                    .filter((item) => item.isDeleted === false && item.id! > 0)
                    .forEach(async (feedbackItem) => {
                      await PathcEventFeedbackAsync(
                        response.id!,
                        feedbackItem.id!,
                        feedbackItem
                      );
                    });
                }
              }
            }
            counterLoopingPlaylist++;
          });
          eventFeedbacks.forEach((feedbackItem) => {
            //delete feedback
            if (feedbackItem.isDeleted) {
              DeleteEventFeedbackAsync(response.id!, feedbackItem.id!);
            }
          });
        })
        .finally(() => appStore.hideLoading());

      navigate('/myevents');
    }
  };

  const toastrEventSave = (isError: boolean) => {
    if (isError) {
      toastStore.show('My events', <div>Failed to save event.</div>, 'error');
    } else {
      toastStore.show('My events', <div>Event saved.</div>, 'success');
    }
  };

  const addNewActivityHandler = () => {
    let newEventPlaylists: EventPlaylistMapper[] = [...eventPlaylists];
    const startDate = new Date();
    const endDate = new Date(new Date().setDate(new Date().getDate() + 30));

    let newPlaylist: EventPlaylistMapper = {
      eventPlaylist: {
        temporaryId: uuid(),
        id: 0,
        eventId: 0,
        status: 'New',
        isGameActivity: false,
        assessmentApproxPax: '',
        isAssessmentActivity: false,
        name: '',
        environmentId: undefined,
        approxPax: '',
        startDateUtc: startDate,
        endDateUtc: endDate,
        timeZone: '',
        language: '',
        notes: '',
        isDeleted: false,
        imageLogoUrl: ''
      },
      locations: [
        {
          id: 0,
          eventPlaylistId: 0,
          locationCountryISO: '',
          country: '',
          city: '',
          isDeleted: false
        }
      ]
    };

    newEventPlaylists.push(newPlaylist);
    SetEventPlaylists(newEventPlaylists);
  };

  const addNewFeedbackHandler = () => {
    let newEventFeedback: EventFeedback[] = [...eventFeedbacks];
    const startDate = new Date();
    const endDate = new Date(new Date().setDate(new Date().getDate() + 30));

    const newData: EventFeedback = {
      id: 0,
      name: '',
      notes: '',
      feedbackId: 0,
      environmentId: 0,
      startDateUtc: startDate,
      endDateUtc: endDate,
      isDeleted: false
    };

    newEventFeedback.push(newData);
    setEventFeedbacks(newEventFeedback);
  };

  const getUserList = async () => {
    let users: User[] = [];

    let response = await GetUsersAsync();

    users = response?.data as User[];

    setUsers(users);
  };

  const handleMenuSelected = (id: number, menu: MenuList) => {
    if (menu.textMenu === 'Edit') {
      setOpenFileDialog(true);
    } else if (menu.textMenu === 'Delete') {
      deleteLogoMediaId();
    }
  };

  const deleteLogoMediaId = () => {
    let newEvent = { ...event };
    newEvent.logoMediaId = null;
    setEvent(newEvent);
    setLogo({ url: '', fileName: '' });
  };

  const generateLogoMenu = () => {
    let newMenus: MenuList[] = [
      { classIcon: 'edit', textMenu: 'Edit', textClass: '' },
      { classIcon: 'delete', textMenu: 'Delete', textClass: 'text-danger' }
    ];

    setMenuList(newMenus);
  };

  const toggleUploadDialog = () => setOpenFileDialog(!openFileDialog);

  const onEditLogo = () => {
    setOpenFileDialog(true);
  };

  const onSelectedFile = (uploadedImage: UploadedImage) => {
    setLogo({ url: uploadedImage.blobUrl, fileName: uploadedImage.fileName });
    setOpenFileDialog(false);
  };

  const onDropFile = (uploadedImage: UploadedImage) => {
    setLogo({ url: uploadedImage.blobUrl, fileName: uploadedImage.fileName });
  };

  const onChangeFeedback = (feedback: EventFeedback, index: number) => {
    let newEventFeedback = cloneDeep(eventFeedbacks);
    newEventFeedback[index] = feedback;
    setEventFeedbacks(newEventFeedback);
  };

  const onDeleteFeedback = async (index: number) => {
    if (eventFeedbacks.length > 0 && index >= 0) {
      let newFeedback = cloneDeep(eventFeedbacks);
      if (newFeedback[index].id && newFeedback[index].id! > 0) {
        newFeedback[index].isDeleted = true;
      } else {
        newFeedback = newFeedback.filter(
          (item, indexItem) => indexItem !== index
        );
      }
      setEventFeedbacks(newFeedback);
    }
  };

  const onCopyFeedback = (index: number) => {
    if (eventFeedbacks.length > 0 && index >= 0) {
      let newFeedback = cloneDeep(eventFeedbacks[index]);
      newFeedback.id = 0;
      let newFeedbacks = cloneDeep(eventFeedbacks);
      newFeedbacks?.push(newFeedback);
      setEventFeedbacks(newFeedbacks);
    }
  };

  const fetchFeedbacks = async () => {
    try {
      appStore.showLoading();
      const response = await getPublishedFeedback(
        `${toDataSourceRequestString(dataState)}`
      );
      setFeedbacksList(response.data);
    } catch (ex) {
      console.error(ex);
    } finally {
      appStore.hideLoading();
    }
  };

  const fetchAssessments = async () => {
    try {
      appStore.showLoading();
      const response = await getPublishedAssessments(
        `${toDataSourceRequestString(dataState)}`
      );
      setAssessmentList(response.data);
    } catch (ex) {
      console.error(ex);
    } finally {
      appStore.hideLoading();
    }
  };

  useEffect(() => {
    populateEvent();
    getUserList();
    getLibraries();
    getEnvironments();
    getLanguages();
    getCountries();
    getTimeZones();
    generateLogoMenu();
    fetchFeedbacks();
    fetchAssessments();
    //eslint-disable-next-line
  }, []);

  return (
    <PrivatePage
      breadcrumb={[
        {
          label: 'Dashboard',
          href: 'dashboard'
        },
        {
          label: 'My events',
          href: 'myevents'
        },
        {
          label: 'Event details',
          href: `myeventdetails/${id}`
        }
      ]}
      pageTitle={'My Events'}>
      {id === 'new' && (
        <div className="d-flex justify-content-end mb-4">
          <HelpSupport
            title="Creating, Copying + Editing an Event"
            url="https://forum.catalystglobal.com/t/my-events-new"
          />
        </div>
      )}
      <Container>
        <Row>
          <Col xs={2}>
            <div className={'d-flex'}>
              <Col xs={'auto'}>
                <CoverImage
                  imageUrl={logo.url}
                  containerStyle={'rounded'}
                  onDropFiles={onDropFile}>
                  <Button
                    fillMode={'flat'}
                    onClick={onEditLogo}
                    rounded={'full'}
                  />
                </CoverImage>
              </Col>

              <div>
                <PopupMenu
                  id={event.id ?? 0}
                  menus={menuList}
                  onMenuSelected={handleMenuSelected}
                />
              </div>
            </div>
          </Col>
          <Col xs={8}>
            <>
              <div className={'card p-4'}>
                <Row className={'p-1'}>
                  <Col md={12}>
                    <FormInput
                      label={'Event title*'}
                      value={event.name}
                      onChange={(e) => onChange('name', e.value)}
                    />
                    {errors?.name && <Error>{errors?.name}</Error>}
                  </Col>
                </Row>
              </div>

              {eventPlaylists &&
                eventPlaylists
                  ?.filter((x) => !x.eventPlaylist!.isDeleted)
                  .map((game, index) => {
                    return (
                      <ActivityEvent
                        key={index}
                        game={game.eventPlaylist}
                        assessments={assessmentList}
                        index={index}
                        users={users}
                        libraries={libraries}
                        environments={environments}
                        languages={languages}
                        countries={countries}
                        timezones={timezones}
                        facilitators={game?.facilitators ?? []}
                        locations={game?.locations ?? []}
                        onChange={onGameChanged}
                        onDeleteGame={handleDeleteGame}
                        onCopyGame={handleCopyGame}
                        onChangeFacilitator={(fc) => {
                          onChangeFacilitatorHandler(index, fc);
                        }}
                        onChangeLocation={(locations) => {
                          onChangeLocationHandler(index, locations);
                        }}
                        onChangeGameSettings={(gameId: number) => {
                          setSelectedGameId(gameId);
                        }}
                      />
                    );
                  })}

              <div className={'card p-4 bg-light mt-4'}>
                <Row
                  className={'p-1 cursor-pointer'}
                  onClick={addNewActivityHandler}>
                  <Col
                    md={12}
                    className={
                      'd-flex justify-content-center align-items-center'
                    }>
                    <span className={'material-symbols-outlined fw-bold p-2'}>
                      event
                    </span>
                    <span className={'fw-bold p-2 '}>Add Activity</span>
                  </Col>
                </Row>
              </div>

              {eventFeedbacks &&
                eventFeedbacks.length > 0 &&
                eventFeedbacks.map((item, index) => {
                  if (!item.isDeleted) {
                    return (
                      <FeedbackEvent
                        key={index}
                        environments={environments}
                        feedback={item}
                        feedbackList={feedbacksList}
                        index={index}
                        onDeleteFeedback={onDeleteFeedback}
                        onChange={onChangeFeedback}
                        onCopyFeedback={onCopyFeedback}
                      />
                    );
                  } else {
                    return null;
                  }
                })}

              <div className={'card p-4 bg-light mt-4'}>
                <Row
                  className={'p-1 cursor-pointer'}
                  onClick={addNewFeedbackHandler}>
                  <Col
                    md={12}
                    className={
                      'd-flex justify-content-center align-items-center'
                    }>
                    <span className={'material-symbols-outlined fw-bold p-2'}>
                      insert_chart
                    </span>
                    <span className={'fw-bold p-2 '}>Add Feedback</span>
                  </Col>
                </Row>
              </div>

              <div className={'mt-4'}>Client/Agent</div>
              <div className={'card p-4'}>
                <Row className={'p-1'}>
                  <Col md={6}>
                    <FormInput
                      label={'Client*'}
                      value={event.clientName}
                      onChange={(e) => onChange('clientName', e.value)}
                    />
                    {errors?.clientName && <Error>{errors?.clientName}</Error>}
                  </Col>
                  <Col md={6}>
                    <FormInput
                      label={'Agent'}
                      value={event.agency}
                      onChange={(e) => onChange('agency', e.value)}
                    />
                  </Col>
                </Row>

                <Row className={'p-1'}>
                  <Col md={6}>
                    <FormInput
                      label={'Contact Name'}
                      value={event.clientContact}
                      onChange={(e) => onChange('clientContact', e.value)}
                    />
                  </Col>
                  <Col md={6}>
                    <FormInput
                      label={'Phone number'}
                      value={event.clientPhoneNumber}
                      onChange={(e) => onChange('clientPhoneNumber', e.value)}
                    />
                  </Col>
                </Row>

                <Row className={'p-1'}>
                  <Col md={6}>
                    <FormInput
                      label={'Client email address'}
                      value={event.clientEmailAddress}
                      type={'email'}
                      onChange={(e) => onChange('clientEmailAddress', e.value)}
                    />
                    {errors?.clientEmailAddress && (
                      <Error>{errors?.clientEmailAddress}</Error>
                    )}
                  </Col>
                  <Col md={6}>
                    <FormInput
                      label={'Industry'}
                      value={event.industry}
                      type={'text'}
                      onChange={(e) => onChange('industry', e.value)}
                    />
                  </Col>
                </Row>
              </div>

              <ExpansionPanel
                title={'Staffing'}
                className={'mt-4'}
                expanded={isExpand}
                onAction={(event: ExpansionPanelActionEvent) =>
                  setIsExpand(!event.expanded)
                }>
                <Reveal>
                  {isExpand && (
                    <ExpansionPanelContent>
                      <div>Sales person</div>
                      <div className={'card p-4'}>
                        <Row className={'p-1'}>
                          <Col md={4}>
                            <Label>Name</Label>
                            <UserComboBox
                              value={{
                                id: event?.salesUserProfileId,
                                fullName: event?.salesName
                              }}
                              clearButton={true}
                              onChange={(e) =>
                                onChangeUserEvent(e, 'salesPerson')
                              }
                            />
                          </Col>
                          <Col md={4}>
                            <FormInput
                              label={'Phone number'}
                              value={event.salesPhoneNumber}
                              onChange={(e) =>
                                onChange('salesPhoneNumber', e.value)
                              }
                            />
                          </Col>
                        </Row>
                        <Row className={'p-1'}>
                          <Col md={4}>&nbsp;</Col>
                          <Col md={8}>
                            <FormInput
                              label={'Email address'}
                              value={event.salesEmailAddress}
                              type={'email'}
                              onChange={(e) =>
                                onChange('salesEmailAddress', e.value)
                              }
                            />
                            {errors?.salesEmailAddress && (
                              <Error>{errors?.salesEmailAddress}</Error>
                            )}
                          </Col>
                        </Row>
                      </div>

                      <div className={'mt-4'}>Lead facilitator</div>
                      <div className={'card p-4'}>
                        <Row className={'p-1'}>
                          <Col md={4}>
                            <Label>Facilitator name</Label>
                            <UserComboBox
                              value={{
                                id: event?.leadFacilitatorUserProfileId,
                                fullName: event?.leadFacilitatorName
                              }}
                              clearButton={true}
                              onChange={(e) =>
                                onChangeUserEvent(e, 'facilitator')
                              }
                            />
                          </Col>
                          <Col md={4}>
                            <FormInput
                              label={'Facilitator phone number'}
                              value={event.leadFacilitatorPhoneNumber}
                              onChange={(e) =>
                                onChange('leadFacilitatorPhoneNumber', e.value)
                              }
                            />
                          </Col>
                        </Row>
                        <Row className={'p-1'}>
                          <Col md={4}>&nbsp;</Col>
                          <Col md={8}>
                            <FormInput
                              label={'Facilitator email address'}
                              value={event.leadFacilitatorEmailAddress}
                              type={'email'}
                              onChange={(e) =>
                                onChange('leadFacilitatorEmailAddress', e.value)
                              }
                            />
                            {errors?.leadFacilitatorEmailAddress && (
                              <Error>
                                {errors?.leadFacilitatorEmailAddress}
                              </Error>
                            )}
                          </Col>
                        </Row>
                      </div>

                      <div className={'mt-4'}>Event director</div>
                      <div className={'card p-4'}>
                        <Row className={'p-1'}>
                          <Col md={4}>
                            <Label>Director name</Label>
                            <UserComboBox
                              value={{
                                id: event?.directorUserProfileId,
                                fullName: event?.directorName
                              }}
                              clearButton={true}
                              onChange={(e) => onChangeUserEvent(e, 'director')}
                            />
                          </Col>
                          <Col md={4}>
                            <FormInput
                              label={'Director phone number'}
                              value={event.directorPhoneNumber}
                              onChange={(e) =>
                                onChange('directorPhoneNumber', e.value)
                              }
                            />
                          </Col>
                        </Row>
                        <Row className={'p-1'}>
                          <Col md={4}>&nbsp;</Col>
                          <Col md={8}>
                            <FormInput
                              label={'Director email address'}
                              value={event.directorEmailAddress}
                              type={'email'}
                              onChange={(e) =>
                                onChange('directorEmailAddress', e.value)
                              }
                            />
                            {errors?.directorEmailAddress && (
                              <Error>{errors?.directorEmailAddress}</Error>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </ExpansionPanelContent>
                  )}
                </Reveal>
              </ExpansionPanel>

              <Row className={'p-1 mt-4'}>
                <Col md={12}>
                  <Label>Event notes</Label>
                  <TextArea
                    rows={10}
                    value={event?.notes}
                    onChange={(e) => onChange('notes', e.value)}
                  />
                </Col>
              </Row>

              <hr />
              <Row className={'mt-3'}>
                <Col
                  md={12}
                  className={'d-flex justify-content-end align-items-center'}>
                  <Button
                    themeColor={'secondary'}
                    className={'w-10 text-light'}
                    onClick={banckHandler}>
                    Cancel
                  </Button>

                  <Button
                    themeColor={'primary'}
                    className={'w-10 text-light ml-2'}
                    onClick={onSubmitHandler}>
                    Save
                  </Button>
                </Col>
              </Row>
            </>
          </Col>
        </Row>
      </Container>
      {openFileDialog && (
        <ResourceWindow
          toggleDialog={toggleUploadDialog}
          onSubmit={onSelectedFile}
          acceptedExtension={'image/*'}
          imageUrl={logo.url}
        />
      )}
      {selectedGameId > 0 && (
        <GameSettingsDialog
          gameId={selectedGameId}
          onConfirm={onConfirmGameSettingsChanges}
          onCancel={() => {
            setSelectedGameId(0);
          }}
        />
      )}
    </PrivatePage>
  );
}
