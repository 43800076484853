import { GameDocument } from '../../../types/game-document';
import { PreGameSettings } from '../../../types/game-document/settings';
import { uuid } from '../../../types/common-helper';
import { MergeGameSettings } from './index';

/**
 * Adds a new Setting to the Game document.
 * @param gameDocument - The Game Document to modify
 * @param name - The Name of the new Setting
 * @param description - The Description for the new Setting
 * @returns The updated Game Document
 */
export const AddPreGameSettingAsync = async (
  gameDocument: GameDocument,
  name: string,
  description: string
) => {
  let preGameSetting = {
    id: uuid(),
    name: name,
    description: description,
    showPreGameLobby: false,
    isPreGameTeamRequired: false,
    showPreGameInfo: true,
    showPreGameTeams: false,
    showPreGameCreateTeams: false,
    showPreGameJoinLeaveTeams: false,
    showPreGameInventory: false,
    showPreGameRoles: false,
    showPreGameScoreboard: false,
    showPreGameChat: true,
    showPreGameFacilitatorChat: true,
    showPreGameAllPlayerChat: false,
    showPreGameDirectChat: false,
    showPreGameTeamChat: false,
    showPreGameGroupChat: false,
    showPreGameTrade: false
  };
  return MergeGameSettings(gameDocument, preGameSetting, 'preGame');
};

/**
 * Updates the identified Setting in the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param preGameSetting - The new pre game settings
 * @constructor
 */
export const UpdatePreGameSettingAsync = async (
  gameDocument: GameDocument,
  preGameSetting: PreGameSettings
) => {
  return MergeGameSettings(gameDocument, preGameSetting, 'preGame');
};
